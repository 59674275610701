import { Modal } from 'reactstrap';
import { useState, useEffect } from 'react';
import { classes } from './modalClasses'; // Assuming you have a similar styles object
import { useTranslation } from 'react-i18next';
import { adminWebsiteDetailsSelector } from 'store/Admin/WebsiteDisplay';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import axiosPixLink from 'config/axiosPixLink';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import ErrorComponent from 'components/shared/ErrorComponent';
import { LanguageSearchField } from 'components/Netlinking/AutoComplete/languageAutoComplete';
import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import Calendar from 'components/Netlinking/Calendar/CalendarPicker';
import ExcelUploader from 'components/shared/UploadExcel/UploadExcel';
import { getLanguageIdByName } from 'utils/netlinking/languages';
import { Loader } from 'components/shared/Loader';
import { PlatformAutoComplete } from 'components/Netlinking/AutoComplete/PlatformAutoComplete';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';

export const UploadSpotModal = (props) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleModalClose = () => {
        setModalOpen(false);
        props.handleClose();
    };

    const { id } = useParams();
    let { formData, isLoading, isError } = useSelector(
        adminWebsiteDetailsSelector
    );
    const { t } = useTranslation();
    const history = useHistory();
    const [urlSite, setUrlSite] = useState(null);
    const [errorSuccess, setErrorSuccess] = useState(false);
    const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
    const [creationLoading, setCreationLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState('');
    const [netlinking, setNetlinking] = useState(null);

    const [netlinkingDomain, setNetlinkingDomain] = useState('');
    const [netlinkingKwPositioned, setNetlinkingKwPositioned] = useState('');
    const [netlinkingTrafic, setNetlinkingTrafic] = useState('');
    const [netlinkingTF, setNetlinkingTF] = useState('');
    const [netlinkingCF, setNetlinkingCF] = useState('');
    const [netlinkingTarifHr, setNetlinkingTarifHr] = useState('');
    const [netlinkingTarifRc, setNetlinkingTarifRc] = useState('');
    const [netlinkingContactedTime, setNetlinkingContactedTime] = useState('');
    const [netlinkingContact, setNetlinkingContact] = useState('');
    const [netlinkingThemesList, setNetlinkingThemesList] = useState('');
    const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
    const [netlinkingThemes, setNetlinkingThemes] = useState([]);
    const [netlinkingTopics, setNetlinkingTopics] = useState([]);
    const [netlinkingPlatformHr, setNetlinkingPlatformHr] = useState('');
    const [netlinkingPlatformRc, setNetlinkingPlatformRc] = useState('');
    const [netlinkingPlatformName, setNetlinkingPlatformName] = useState('');
    const [netlinkingType, setNetlinkingType] = useState('');
    function handleTypeCheckboxChange(newStates) {
        if (
            !newStates.checkbox1 &&
            !newStates.checkbox2 &&
            !newStates.checkbox3 &&
            !newStates.checkbox0
        ) {
            setNetlinkingType('');
        }
        if (newStates.checkbox1) {
            setNetlinkingType('standard');
        } else if (newStates.checkbox2) {
            setNetlinkingType('premium');
        } else if (newStates.checkbox3) {
            setNetlinkingType('redType');
        } else if (newStates.checkbox0) {
            setNetlinkingType('ultraPremium');
        }
    }
    function handleLanguageChange(newLanguage) {
        setNetlinkingLanguage(newLanguage);
    }
    function handlePlatformChange(platformName) {
        setNetlinkingPlatformName(platformName);
    }
    function handleThemeChange(newTheme) {
        if (
            newTheme !== null &&
            newTheme !== undefined &&
            !netlinkingThemes.includes(newTheme)
        ) {
            setNetlinkingThemes([...netlinkingThemes, newTheme]);
        }
    }
    function handleTopicChange(newTheme) {
        if (
            newTheme !== null &&
            newTheme !== undefined &&
            !netlinkingTopics.includes(newTheme)
        ) {
            setNetlinkingTopics([...netlinkingTopics, newTheme]);
        }
    }

    const handleContactedTimeChange = (value) => {
        setNetlinkingContactedTime(value);
    };

    const createNetlinking = async () => {
        props.executeAction([{
            "domain": netlinkingDomain,
            "kwPositioned": netlinkingKwPositioned,
            "trafic": netlinkingTrafic,
            "tF": netlinkingTF,
            "cF": netlinkingCF,
            "tarifHr": netlinkingTarifHr,
            "tarifRc": netlinkingTarifRc,
            "languageId": getLanguageIdByName(netlinkingLanguage),
            "contact": netlinkingContact,
            "themes": netlinkingThemes,
            "topics": netlinkingTopics,
            "contactedTime": netlinkingContactedTime,
            "platformName": netlinkingPlatformName,
            "platformHr": netlinkingPlatformHr,
            "platformRc": netlinkingPlatformRc,
            "type": netlinkingType === 'redType' ? 'redList' : netlinkingType,
        }])
    };
    function removeTheme(theme) {
        const updatedThemes = netlinkingThemes.filter((t) => t !== theme);
        setNetlinkingThemes(updatedThemes);
    }

    function removeTopic(theme) {
        const updatedTopics = netlinkingTopics.filter((t) => t !== theme);
        setNetlinkingTopics(updatedTopics);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('locationchange'));
    }, []);

    if (!isError) return <ErrorComponent />;


    return (
        <Modal
            style={classes.superLargeModalCustom}
            isOpen={props.open}
            toggle={handleModalClose}
            backdrop={true}
            keyboard={false}
        >
            <div className="content-center">
                {creationLoading && <Loader />}
                <div className="row">
                    <div className="col-12 col-md-12 box-screen">

                        <div className="box-img-bg bg-9" />
                        <div className="header-title">{t('Fiche de Spot Netlinking')} </div>

                        <div className="box-wrapper">
                            <div className="row">
                                <div className="col-12 col-md-12">
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-4">
                                            <div className="label-title">{t('Domaine Netlinking')}</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-ct"
                                                    value={netlinkingDomain}
                                                    onChange={(e) => setNetlinkingDomain(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-12">
                                            <div className="label-title">
                                                {t('Netlinking Thématique')}
                                            </div>
                                            <div>
                                                <ThemeSearchField
                                                    onThemeChange={handleThemeChange}
                                                    highlightedOptions={netlinkingThemes}
                                                ></ThemeSearchField>
                                            </div>
                                            <div className="theme-list-container-filter">
                                                {netlinkingThemes.map((theme, index) => (
                                                    <span key={index} className="theme-container">
                                                        <span>{theme}</span>
                                                        <button onClick={() => removeTheme(theme)}>x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row row-mb">
                                        <div className="col-12 col-md-12">
                                            <div className="label-title">
                                                {t('Netlinking Topic Majestic')}
                                            </div>
                                            <div>
                                                <ThemeSearchField
                                                    onThemeChange={handleTopicChange}
                                                    highlightedOptions={netlinkingTopics}
                                                ></ThemeSearchField>
                                            </div>
                                            <div className="theme-list-container-filter">
                                                {netlinkingTopics.map((theme, index) => (
                                                    <span key={index} className="theme-container">
                                                        <span>{theme}</span>
                                                        <button onClick={() => removeTopic(theme)}>x</button>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-2">
                                            <div className="label-title">{t('Langue')}</div>
                                            <div>
                                                <LanguageSearchField
                                                    onLanguageChange={handleLanguageChange}
                                                ></LanguageSearchField>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-10">
                                            <div className="label-title">{t('Type de spots')} :</div>
                                            <TypeCheckBoxGroup
                                                onCheckboxChange={handleTypeCheckboxChange}
                                                initialStateString={netlinkingType}
                                            />
                                        </div>
                                    </div>
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                            <div className="label-title">{t('Trafic')}</div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingTrafic}
                                                    onChange={(e) => setNetlinkingTrafic(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '10%' }}>
                                            <div className="label-title">{t('TF')}</div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingTF}
                                                    onChange={(e) => setNetlinkingTF(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '10%' }}>
                                            <div className="label-title">{t('CF')}</div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingCF}
                                                    onChange={(e) => setNetlinkingCF(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '20%' }}>
                                            <div className="label-title">
                                                {t('Mots clés positionnés')}
                                            </div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingKwPositioned}
                                                    onChange={(e) =>
                                                        setNetlinkingKwPositioned(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-mb">
                                        <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                            <div className="label-title">{t('Tarif') + ' HR'} </div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingTarifHr}
                                                    onChange={(e) => setNetlinkingTarifHr(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-2" style={{ width: '10%' }}>
                                            <div className="label-title">{t('Tarif') + ' RC'}</div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingTarifRc}
                                                    onChange={(e) => setNetlinkingTarifRc(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '15%' }}>
                                            <div className="label-title">{t('Nom platforme')}</div>
                                            <PlatformAutoComplete
                                                onPlatformChange={handlePlatformChange}
                                            ></PlatformAutoComplete>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '15%' }}>
                                            <div className="label-title">
                                                {t('Tarif plateforme') + ' HR'}
                                            </div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingPlatformHr}
                                                    onChange={(e) =>
                                                        setNetlinkingPlatformHr(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3" style={{ width: '15%' }}>
                                            <div className="label-title">
                                                {t('Tarif plateforme') + ' RC'}
                                            </div>
                                            <div className="number-wrapper">
                                                <input
                                                    type="number"
                                                    min="0"
                                                    className="form-control form-ct"
                                                    value={netlinkingPlatformRc}
                                                    onChange={(e) =>
                                                        setNetlinkingPlatformRc(e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row row-mb">

                                        <div className="col-12 col-md-4">
                                            <div className="label-title">{t('Contacted Date')}</div>
                                            <Calendar
                                                onValueChange={handleContactedTimeChange}
                                            ></Calendar>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="label-title">{t('Contact')}</div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-ct"
                                                    value={netlinkingContact}
                                                    onChange={(e) => setNetlinkingContact(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-button-bt">

                            <span>
                                <button onClick={createNetlinking} className="bt-submit-y me-2">
                                    {t('Sauvegarder')}
                                </button>{' '}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UploadSpotModal;
