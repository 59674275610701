import './shared.css';

export const CustomSlider = (props) => {
  if (!props.disabled)
    return (
      <div className="slidecontainer">
        <input
          type="range"
          min={1}
          max={3}
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
          className={`sliderd slider${props.value}`}
        />
        <span className="ic-false" />
        <span className="ic-true" />
      </div>
    );

  return (
    <div className="slidecontainer hover-element-pointer">
      <input
        disabled={true}
        type="range"
        min={1}
        max={3}
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
        className={`sliderd slider${props.value} slider-disabled`}
      />
      <span className="" />
      <span className="" />
    </div>
  );
};
