import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useState, useEffect } from 'react';
import { classes } from './modalClasses'; // Assuming you have a similar styles object
import { Feedback } from '@mui/icons-material';

export const FeedbackModal = (props) => {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);


    const [feedbackArray, setFeedbackArray] = useState([]); // Array to store domains and commentaries
    const [commentary, setCommentary] = useState(''); // Input for commentary
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const rowsPerPage = 5; // Number of rows per page

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentPage(1)
        props.handleClose();
    };




    const totalPages = Math.ceil(feedbackArray.length / rowsPerPage);
    const paginatedRows = props.array.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    useEffect(() => {
        if (props.open) {
            setModalOpen(true);
        }
    }, [props.open]);


    useEffect(() => {
        if (props.array) {
            setFeedbackArray(props.array);
        }
    }, [props.array]);



    function updateCommantary(itemId, value) {
        const sortedupdatedItems = feedbackArray.map((item) => {
            if (item.netlinking.id === itemId) {
                return { ...item, feedback: value };
            }

            return item;
        });
        setFeedbackArray(sortedupdatedItems)

    }


    return (
        <Modal
            style={classes.superLargeModalCustom}
            isOpen={props.open}
            toggle={handleModalClose}
            backdrop={true}
            keyboard={false}
        >
            <div style={classes.modalHeader} className="modal-header">
                <h5 style={classes.title} className="modal-title">
                    {t('Commentaire')}
                </h5>
                <button
                    type="button"
                    onClick={handleModalClose}
                    style={classes.btnClose}
                    className="btn-close"
                    aria-label="Close"
                />
            </div>
            <div style={classes.modalPad} className="modal-body">
                <div className="row row-mb-lg">
                    <div className="col-12">
                        <p style={classes.textConfirm}>{t('Vos commentaires nous intéressent !')}</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center", // Centers horizontally
                        }}
                    >
                        <img
                            style={{
                                height: '150px'
                            }}
                            src="/Images/chicken/feedbackStress.gif"
                            alt="download icon"
                        />
                    </div>

                </div>
                {/* Table to Display Feedback Array */}
                <div className="table-responsive">
                    <div className="box-table-netlinking">
                        <table className="table-lists">
                            <thead>
                                <tr>
                                    <th>{t('Domaine')}</th>
                                    <th>{t('Commentaire')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedRows.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.netlinking.domain}</td>
                                        <td>
                                            <textarea
                                                value={item.commentary}
                                                onChange={(e) => updateCommantary(item.netlinking.id, e.target.value)}
                                                placeholder={t('Commentaire')}
                                                style={classes.textarea}
                                                className="form-control"
                                            /></td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Pagination Controls */}
                {totalPages > 1 && (
                    <>
                        <div className="box-pagination">
                            {feedbackArray.length === 0 || currentPage === 1 ? (
                                <div style={{ cursor: 'not-allowed' }} className="text-page">
                                    {t('Précédent')}
                                </div>
                            ) : (
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                >
                                    {t('Précédent')}
                                </div>
                            )}

                            <div className="list-page-number">
                                {
                                    <span
                                        className={'active'}
                                    >
                                        {currentPage}
                                    </span>
                                }
                                {feedbackArray.length === 0 ||
                                    currentPage === Math.ceil(feedbackArray.length / 5) ? (
                                    <span style={{ cursor: 'not-allowed' }} className="text-page">
                                        {t('Suivant')}
                                    </span>
                                ) : (
                                    <span onClick={() => setCurrentPage(currentPage + 1)}>
                                        {t('Suivant')}
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <div className="d-flex justify-content-end mt-5">

                    <button
                        style={{ backgroundColor: '#43b929' }}
                        className="confirm"
                        onClick={() => { props.onSubmit(feedbackArray) }}
                    >

                        {t('Confirmer')}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default FeedbackModal;
