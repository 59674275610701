import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import LanguageCheckBoxGroup from 'components/Netlinking/CheckBox/LanguageCheckBox';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import CheckBox from 'components/shared/CheckBox';
import {
  CustomTooltip,
  SmallCustomTooltip,
} from 'components/shared/CustomTooltip';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { Loader } from 'components/shared/Loader';
import PopoverSpotBig from 'components/shared/PopoverSpot/PopoverSpotsBig';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'reactstrap';
import {
  PAGE_NB_OPTIONS,
  PIXLINK_CAMPAIGN_STATUS,
  PIXLINK_STATUS,
} from 'utils/Constants';
import { ClientSelectedSpotTable } from './ClientSelectedSpotTable';
import { CustomDropdownMultipleStatusSpots } from 'components/shared/DropDown/CustomDropdownStatusSpots';
import PopoverSpotMenu from 'components/shared/DropDown/NewDropDownMenu';
import PopoverSpotMedium from 'components/shared/PopoverSpot/PopoverSpotsMedium';
import {
  ExcelExportButtonSourcing,
  ExcelExportButtonSourcingClient,
} from 'components/shared/ExcelExport/SourcingExport/ExcelExportButtonSourcingClient';
import { getLanguageIdByName } from 'utils/netlinking/languages';
import { CustomSlider } from 'components/shared/CustomSlider';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import {
  calculateClientMinimumLinks,
  calculateMinimumLinks,
} from 'utils/netlinking/linksNumber';
export default function ClientSelectedSpotTableControl({
  netlinkings,
  campaignFilter,
  wantedNetlinkings,
  removeNetlinkingId,
  SaveNetlinkingToCampaigns,
  sendUpdateSaveSelectedNetlinking,
  sendNewWantedNetlinkingExport,
  nbrOfLinksDemanded,
  campaignStatus,
  updateSingleItemStatus,
  historicBackLinkings,
  campaignType
}) {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();
  const history = useHistory();
  const [netlinkingType, setNetlinkingType] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [filteringLoading, setFilteringLoading] = useState(false);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dataExcelToExport, setDataExcelToExport] = useState([]);
  const [filterCellSelected, setFilterCellSelected] = useState(false);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arraylength, setArrayLength] = useState(-1);
  const [validateGroupSlider, setValidateGroupSlider] = useState(2);

  const sendLengthArrayShown = (length) => {
    setArrayLength(length);
  };
  const handleValidateGroup = (value) => {
    console.log(value)
    if (dataExcelToExport?.length > 0) {
      const filteredIds = dataExcelToExport.map((item) => item.netlinking.id);
      if (value === '1') {
        const idSet = new Set();
        // Add the ids to the Set
        netlinkingFilteredList
          .filter(
            (item) =>
              item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
              item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT
          )
          .forEach((item) => {
            idSet.add(item.netlinking.id);
          });
        dataExcelToExport.forEach((item) => {
          idSet.add(item.netlinking.id);
        });
        if (
          idSet.size >
          Math.floor(
            calculateMinimumLinks(nbrOfLinksDemanded) -
            calculateClientMinimumLinks(nbrOfLinksDemanded)
          )
        ) {
          setIsSuccess(false);
          setErrorSuccessMsg(t('Vous avez dépassé les quotas de refus'));
          setErrorSuccess(true);
        } else {
          const newnetlinkingFilteredList = netlinkingFilteredList.map(
            (item) => {
              if (filteredIds.includes(item.netlinking.id)) {
                return {
                  ...item,
                  spotTmpStatus: PIXLINK_STATUS.REFUSED_CLIENT,
                };
              }
              return item;
            }
          );
          setNetlinkingFilteredList(newnetlinkingFilteredList);
          setValidateGroupSlider(value);
          setTimeout(() => {
            const newnetlinkingFilteredList2 = newnetlinkingFilteredList.map(
              (item) => {
                return {
                  ...item,
                  spotTmpStatus2: PIXLINK_STATUS.REFUSED_CLIENT,
                };
              }
            );
            setNetlinkingFilteredList(newnetlinkingFilteredList2);
            sendUpdateSaveSelectedNetlinking(newnetlinkingFilteredList2);
            updateSingleItemStatus(
              newnetlinkingFilteredList
                .filter(
                  (item) =>
                    item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
                    item.spotTmpStatus2 === PIXLINK_STATUS.REFUSED_CLIENT
                )
                .map((item) => {
                  return { ...item, spotStatus: PIXLINK_STATUS.REFUSED_CLIENT };
                })
            );
          }, 20);
        }
      }
      if (value === '2') {
        const idSet = new Set();
        // Add the ids to the Set
        netlinkingFilteredList
          .filter(
            (item) =>
              item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
              item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
          )
          .forEach((item) => {
            idSet.add(item.netlinking.id);
          });
        dataExcelToExport.forEach((item) => {
          idSet.add(item.netlinking.id);
        });
        const newnetlinkingFilteredList = netlinkingFilteredList.map(
          (item) => {
            if (filteredIds.includes(item.netlinking.id)) {
              return {
                ...item,
                spotTmpStatus: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
              };
            }
            return item;
          }
        );
        setNetlinkingFilteredList(newnetlinkingFilteredList);
        setValidateGroupSlider(value);
        setTimeout(() => {
          const newnetlinkingFilteredList2 = newnetlinkingFilteredList.map(
            (item) => {
              return {
                ...item,
                spotTmpStatus2: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
              };
            }
          );
          setNetlinkingFilteredList(newnetlinkingFilteredList2);
          sendUpdateSaveSelectedNetlinking(newnetlinkingFilteredList2);
          updateSingleItemStatus(
            newnetlinkingFilteredList
              .filter(
                (item) =>
                  item.spotTmpStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT ||
                  item.spotTmpStatus2 === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
              )
              .map((item) => {
                return { ...item, spotStatus: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT };
              })
          );
        }, 20);

      }
      else if (value === '3') {
        const newnetlinkingFilteredList = netlinkingFilteredList.map((item) => {
          if (filteredIds.includes(item.netlinking.id)) {
            return {
              ...item,
              spotTmpStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
            };
          }
          return item;
        });
        setNetlinkingFilteredList(newnetlinkingFilteredList);
        setValidateGroupSlider(value);
        setTimeout(() => {
          const newnetlinkingFilteredList2 = newnetlinkingFilteredList.map(
            (item) => {
              return {
                ...item,
                spotTmpStatus2: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
              };
            }
          );
          setNetlinkingFilteredList(newnetlinkingFilteredList2);
          sendUpdateSaveSelectedNetlinking(newnetlinkingFilteredList2);
          updateSingleItemStatus(
            newnetlinkingFilteredList
              .filter(
                (item) =>
                  item.spotTmpStatus ===
                  PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT ||
                  item.spotTmpStatus2 === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
              )
              .map((item) => {
                return {
                  ...item,
                  spotStatus: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
                };
              })
          );
        }, 20);
      }
    } else {
      setValidateGroupSlider(value);
    }
  };
  const [selectedStatus, setSelectedStatus] = useState(
    campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED ? [PIXLINK_STATUS.TO_BE_COMMANDED] :
      campaignStatus ===
        PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED
        ? [PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT]
        : campaignStatus ===
          PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED
          ? [PIXLINK_STATUS.TO_BE_COMMANDED]
          : ['']
  );
  const [spotsColumns, setSpotColumns] = useState(
    JSON.parse(localStorage.getItem('netlinkingColumnBigCache')) !== null
      ? JSON.parse(localStorage.getItem('netlinkingColumnBigCache'))
        .updatedColumns
      : [
        { name: 'domain', label: t('Domaine'), visible: true },
        { name: 'redactionState', label: t('Etat rédaction'), visible: true },
        {
          name: 'publicationState',
          label: t('Etat publication'),
          visible: true,
        },

        {
          name: 'programationDate',
          label: t('Date de programmation'),
          visible: true,
        },
        {
          name: 'publicationDate',
          label: t('Date de publication estimé'),
          visible: true,
        },
        { name: 'articleLink', label: t('lien article'), visible: true },
        { name: 'spotLink', label: t('lien du spot'), visible: true },


        { name: 'ancreLink', label: t('ancre de lien'), visible: true },
        { name: 'inseredLink', label: t('lien à insérer'), visible: true },
      ]
  );

  const filterStatusMenuList = [
    { text: t('Tous les status'), status: '' },
    {
      text: t('A valider client'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
    },
    {
      text: t('Validé client'),
      status: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
    },
    { text: t('A commander'), status: PIXLINK_STATUS.TO_BE_COMMANDED },
    { text: t('Refusé client'), status: PIXLINK_STATUS.REFUSED_CLIENT },
  ];
  function getStatusText(status) {
    const item = filterStatusMenuList.find((item) => item.status === status);
    return item ? item.text : 'Status not found';
  }

  const removeString = (stringToRemove) => {
    setSelectedStatus(
      selectedStatus.filter((string) => string !== stringToRemove)
    );
  };

  const toggleFilterCellSelected = () => {
    setFilterCellSelected((prev) => !prev);
  };
  function handleDataExcelToExport(data) {
    sendNewWantedNetlinkingExport(data)
    setDataExcelToExport(data);
  }

  const handleClickeStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleCloseStatusFilter = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const status = campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED
      ? [PIXLINK_STATUS.TO_BE_COMMANDED]
      : campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED
        ? [PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT]
        : campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED
          ? [PIXLINK_STATUS.TO_BE_COMMANDED]
          : [''];

    setSelectedStatus(status);
  }, [campaignStatus]);

  const handleClickStatusFilter = (status) => {
    const selectedIndex = selectedStatus.indexOf(status);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStatus, status);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStatus.slice(1));
    } else if (selectedIndex === selectedStatus.length - 1) {
      newSelected = newSelected.concat(selectedStatus.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStatus.slice(0, selectedIndex),
        selectedStatus.slice(selectedIndex + 1)
      );
    }
    if (newSelected.includes('') && !selectedStatus.includes('')) {
      newSelected = [''];
    } else {
      newSelected = newSelected.filter((e) => e !== '');
    }

    setSelectedStatus(newSelected);
  };

  function handleTypeCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox0
    ) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
    } else if (newStates.checkbox0) {
      setNetlinkingType('ultraPremium');
    }
  }

  function handleThemeChange(newTheme) {
    if (
      newTheme !== null &&
      newTheme !== undefined &&
      !netlinkingTheme.includes(newTheme)
    ) {
      setNetlinkingTheme([...netlinkingTheme, newTheme]);
    }
  }

  function handleActiveAdvancedFilter() {
    setActiveAdvancedFilter(!activeAdvancedFilter);
  }

  function removeTheme(theme) {
    const updatedThemes = netlinkingTheme.filter((t) => t !== theme);
    setNetlinkingTheme(updatedThemes);
  }
  function handleLanguageCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox4 &&
      !newStates.checkbox5 &&
      !newStates.checkbox6 &&
      !newStates.checkboxExternal
    ) {
      setNetlinkingLanguage('');
    }
    if (newStates.checkbox1) {
      setNetlinkingLanguage(6);
    } else if (newStates.checkbox2) {
      setNetlinkingLanguage(1);
    } else if (newStates.checkbox3) {
      setNetlinkingLanguage(3);
    } else if (newStates.checkbox4) {
      setNetlinkingLanguage(2);
    } else if (newStates.checkbox5) {
      setNetlinkingLanguage(4);
    } else if (newStates.checkbox6) {
      setNetlinkingLanguage(5);
    } else if (newStates.checkboxExternal) {
      setNetlinkingLanguage(getLanguageIdByName(newStates.checkboxExternal));
    }
  }
  async function removeLanguageFilter() {
    setNetlinkingLanguage('');
  }

  async function removeNetlinkingContactFilter() {
    setNetlinkingContactFilter('');
  }
  function runSearchFunction() {
    setFilteringLoading(true);
    searchFunction();
    setShowTable(true);
    setPopupOpen(false);
    setFilteringLoading(false);
  }
  function searchFunction() {
    let tmpnetlinkingTypeBrut = netlinkingList;
    if (netlinkingType === 'redType') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) =>
          item.netlinking.type === 'redList' ||
          item.netlinking.type === 'liste rouge' ||
          item.netlinking.type === 'Liste Rouge' ||
          item.netlinking.type === 'Liste rouge'
      );
    } else if (netlinkingType === 'standard') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'standard'
      );
    } else if (netlinkingType === 'premium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'premium'
      );
    } else if (netlinkingType === 'ultraPremium') {
      tmpnetlinkingTypeBrut = netlinkingList.filter(
        (item) => item.type === 'ultraPremium'
      );
    }
    let tmpNetlinkingLanguage = tmpnetlinkingTypeBrut;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = tmpNetlinkingLanguage.filter(
        (item) => item.netlinking.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) =>
            row.netlinking.themesList !== null &&
            row.netlinking.themesList
              .toLowerCase()
              .includes(value.toLowerCase().trim())
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.netlinking.tF >= TFValues[0] &&
          item.netlinking.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.netlinking.trafic >= TraficValues[0] &&
          item.netlinking.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.netlinking.tarifHr >= TarifHRValues[0] &&
          item.netlinking.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.netlinking.tarifRc >= TarifRCValues[0] &&
          item.netlinking.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.netlinking.kwPositioned >= Kwvalues[0] &&
          item.netlinking.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.netlinking.contact != null &&
          item.netlinking.contact != undefined &&
          item.netlinking.contact.includes(netlinkingContactFilter)
      );
    }
    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);
    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Français';
        break;
      case 1:
        result = 'English';
        break;
      case 2:
        result = 'Deutsch';
        break;
      case 3:
        result = 'Español';
        break;
      case 4:
        result = 'Italiano';
        break;
      case 5:
        result = 'Português';
        break;
      default:
        result = 'Français';
        break;
    }

    return result;
  }

  const handleValueNetlinkingContactFilter = (value) => {
    setNetlinkingContactFilter(value);
  };
  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    netlinkingContactFilter,
  ]);

  useEffect(() => {
    setNetlinkingList(netlinkings);
    setNetlinkingFilteredList(netlinkings);
  }, [netlinkings]);

  useEffect(() => {
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setFilteringLoading(true);
    }
    setFilteringLoading(true);

    window.dispatchEvent(new Event('locationchange'));

    /* setTimeout(() => {*/
    if (
      window.location.href.split('/')[
      window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setShowTable(true);
    }
    setTimeout(() => {
      if (campaignFilter != null) {
        let CampaignFilterClean = campaignFilter;
        CampaignFilterClean.minKeywordPositioned =
          CampaignFilterClean.minKeywordPositioned
            ? CampaignFilterClean.minKeywordPositioned
            : 0;
        CampaignFilterClean.maxKeywordPositioned =
          CampaignFilterClean.maxKeywordPositioned
            ? CampaignFilterClean.maxKeywordPositioned
            : Infinity;
        CampaignFilterClean.minTF = CampaignFilterClean.minTF
          ? CampaignFilterClean.minTF
          : 0;
        CampaignFilterClean.maxTF = CampaignFilterClean.maxTF
          ? CampaignFilterClean.maxTF
          : Infinity;
        CampaignFilterClean.minTarifRC = CampaignFilterClean.minTarifRC
          ? CampaignFilterClean.minTarifRC
          : 0;
        CampaignFilterClean.maxTarifRC = CampaignFilterClean.maxTarifRC
          ? CampaignFilterClean.maxTarifRC
          : Infinity;
        CampaignFilterClean.minTraffic = CampaignFilterClean.minTraffic
          ? CampaignFilterClean.minTraffic
          : 0;
        CampaignFilterClean.maxTraffic = CampaignFilterClean.maxTraffic
          ? CampaignFilterClean.maxTraffic
          : Infinity;
        CampaignFilterClean.minTarifHR = CampaignFilterClean.minTarifHR
          ? CampaignFilterClean.minTarifHR
          : 0;
        CampaignFilterClean.maxTarifHR = CampaignFilterClean.maxTarifHR
          ? CampaignFilterClean.maxTarifHR
          : Infinity;
        //keywordPositionned
        setKwvalues([
          CampaignFilterClean.minKeywordPositioned,
          CampaignFilterClean.maxKeywordPositioned,
        ]);
        setMaxKwValue(CampaignFilterClean.maxKeywordPositioned);
        setMinKwValue(CampaignFilterClean.minKeywordPositioned);

        //TfValues
        setTFValues([CampaignFilterClean.minTF, CampaignFilterClean.maxTF]);
        setMaxTFValue(CampaignFilterClean.maxTF);
        setMinTFValue(CampaignFilterClean.minTF);

        //TarifRc
        setTarifRCValues([
          CampaignFilterClean.minTarifRC,
          CampaignFilterClean.maxTarifRC,
        ]);
        setMaxTarifRCValue(CampaignFilterClean.maxTarifRC);
        setMinTarifRCValue(CampaignFilterClean.minTarifRC);

        //Traffic
        setTraficValues([
          CampaignFilterClean.minTraffic,
          CampaignFilterClean.maxTraffic,
        ]);
        setMaxTraficValue(CampaignFilterClean.maxTraffic);
        setMinTraficValue(CampaignFilterClean.minTraffic);

        //TarifHr
        setTarifHRValues([
          CampaignFilterClean.minTarifHR,
          CampaignFilterClean.maxTarifHR,
        ]);
        setMaxTarifHRValue(CampaignFilterClean.maxTarifHR);
        setMinTarifHRValue(CampaignFilterClean.minTarifHR);
        setNetlinkingLanguage(CampaignFilterClean.languageId);
        setNetlinkingTheme(
          CampaignFilterClean.themes.map((themeItem) => themeItem.theme)
        );
        setNetlinkingType(CampaignFilterClean.type);
      }

      setFilteringLoading(false);
    }, '1000');
    /*  }, '100');*/
  }, []);

  const handleCheckBoxColumnsToggle = (index) => {
    const updatedColumns = [...spotsColumns];
    updatedColumns[index].visible = !updatedColumns[index].visible;
    localStorage.setItem(
      'netlinkingColumnBigCache',
      JSON.stringify({
        updatedColumns,
      })
    );
    setSpotColumns(updatedColumns);
  };
  return (
    <div>
      <div className="content-center">
        <div className="box-header-top-dm header-info">
          {true &&
            (filteringLoading ? (
              <h1>{'XXX' + ' ' + t('Spot(s) trouvé(s)')}</h1>
            ) : (
              <h1>
                {netlinkingFilteredList.length === 0
                  ? '0' + ' ' + t('Spot(s) trouvé(s)')
                  : arraylength > -1
                    ? arraylength + ' ' + t('Spot(s) trouvé(s)')
                    : netlinkingFilteredList.length +
                    ' ' +
                    t('Spot(s) trouvé(s)')}
              </h1>
            ))}
        </div>
        <CustomTooltip
          title={
            <span className="black-color">
              <p>
                {t('Orange')} :{' '}
                {t('Validé SA ou super consultant, à traiter client')}
              </p>
              <p>
                {t('Bleu')} : {t('Validé client, à trier Consultant')}
              </p>
              <p>
                {t('Vert')} : {t('Validé client, à commander')}
              </p>
              <p>
                {t('Rouge')} : {t('Refusé client')}
              </p>
            </span>
          }
          placement="top"
        >
          <button
            type="button"
            className="btn-circle"
            style={{ marginLeft: '5px' }}
          ></button>
        </CustomTooltip>
        <br></br>
        <PopoverSpotMenu
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        >
          <button
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={(e) => handleClickeStatusFilter('-1', e)}
            style={{
              padding: '5px',
              border: 'none',
              background: 'none',
              marginBottom: '25px',
            }}
          >
            <span>
              <span className="theme-container-grey click-pointer">
                <span>{t('Status spot:')}</span>
              </span>
            </span>
          </button>
          {selectedStatus.length > 0 && (
            <>
              {selectedStatus.map((status, index) => (
                <span key={index} className="theme-container click-pointer">
                  <span>{t(getStatusText(status))}</span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeString(status);
                    }}
                  >
                    x
                  </button>
                </span>
              ))}
            </>
          )}
        </PopoverSpotMenu>
        {/*<CustomDropdownMultipleStatusSpots
          selectedStatus={selectedStatus}
          handleClose={handleCloseStatusFilter}
          anchorEl={anchorEl && anchorEl['-1']}
          list={filterStatusMenuList}
          toggleStatus={handleClickStatusFilter}
          getOptionLabel={(option) => {
            return option.text;
          }}
        />*/}
        {true && filteringLoading && <Loader />}
        {true && (
          <div>
            <div className="input-container-excel">
              <div className="input-group input-cs w-80">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Rechercher par nom de domaine')}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center item-per-table">
                <span className="me-2">{t('Afficher les résultats')}</span>
                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}

                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              {campaignType && (<SmallCustomTooltip
                title={
                  <span>
                    <p className="black-color">{t('Valider la séléction')}</p>

                    {campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED && campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED && (<p className="black-color">{t("Si vous voyez les spots en orange et que vous ne pouvez pas soumettre votre choix cela signifie que l'équipe optimise encore sa recherche nous vous en informerons dès que la recherche sera terminée.")}</p>)}
                  </span>
                }
                placement="top"
              >
                <span style={{ marginRight: '30px' }}>
                  <CustomSlider
                    value={campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED && campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED ? 3 : validateGroupSlider}
                    setValue={(value) => {
                      handleValidateGroup(value);
                    }}
                    disabled={
                      campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED && campaignStatus !==
                      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED
                    }
                  ></CustomSlider>
                </span>
              </SmallCustomTooltip>)}

              <PopoverSpotMedium
                content={spotsColumns}
                isSelected={filterCellSelected}
                onCheckboxToggle={handleCheckBoxColumnsToggle}
              >
                <span
                  className={
                    filterCellSelected
                      ? 'bt-circle-edit-selected'
                      : 'bt-circle-edit'
                  }
                >
                  <img src="Images/edit-cell.png" alt="icon-edit-cell" />
                </span>
              </PopoverSpotMedium>

              {true && netlinkings && netlinkings.length > 0 && (
                <ExcelExportButtonSourcingClient
                  netlinkingData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.netlinking.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingFilteredListData={
                    nameSearch?.length > 0
                      ? netlinkingFilteredList.filter((item) =>
                        item.netlinking.domain.includes(nameSearch)
                      )
                      : netlinkingFilteredList
                  }
                  netlinkingExportList={dataExcelToExport}
                ></ExcelExportButtonSourcingClient>
              )}
            </div>
            <ClientSelectedSpotTable
              shownNetlinkingFilteredList={netlinkingFilteredList}
              nameSearch={nameSearch}
              onNetlinkingContactFilterChange={
                handleValueNetlinkingContactFilter
              }
              contactFilter={netlinkingContactFilter}
              sendNetlinkingExport={handleDataExcelToExport}
              ordersPerPage={ordersPerPage}
              removeNetlinkingId={removeNetlinkingId}
              SaveNetlinkingToCampaigns={SaveNetlinkingToCampaigns}
              columns={spotsColumns}
              sendUpdateSaveSelectedNetlinking={
                sendUpdateSaveSelectedNetlinking
              }
              selectedStatus={selectedStatus}
              nbrOfLinksDemanded={nbrOfLinksDemanded}
              wantedNetlinkings={wantedNetlinkings}
              sendLengthArrayShown={sendLengthArrayShown}
              campaignStatus={campaignStatus}
              updateSingleItemStatus={updateSingleItemStatus}
              historicBackLinkings={historicBackLinkings}
              campaignType={campaignType}
              sendNewWantedNetlinkingExport={sendNewWantedNetlinkingExport}
            />
          </div>
        )}
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          setErrorSuccess(false);
        }}
        success={isSuccess}
      />
    </div>
  );
}
